import * as React from 'react'
import ImageWithText, { ImageWithTextProps } from '../ImageWithText'
import ArrowButton from '../ArrowButton'
import { DynamicSitesCategory, ArrowDirection } from '../../common/typings/enums'
import { useTranslation } from 'react-i18next'
import { sectionStyles } from './model'
import './style.scss'

export interface CaseStudySectionProps {
  caseStudies: ImageWithTextProps[]
  lead?: string
  loadMore?: boolean
  seeMore?: boolean
  casesPerPage?: number
}

const CaseStudySection: React.FC<CaseStudySectionProps> = ({ 
  caseStudies, 
  lead, 
  loadMore = false, 
  seeMore = false,
  casesPerPage = 8,
}) => {

  const { t } = useTranslation()
  const [casesToLoad, setCasesToLoad] = React.useState(casesPerPage)
  const [limitedCaseStudies, setLimitedCaseStudies] = React.useState<ImageWithTextProps[]>([])
  const category = DynamicSitesCategory.WORK
  React.useEffect(() => {
    setLimitedCaseStudies(caseStudies.slice(0, casesToLoad))
  }, [caseStudies, casesToLoad])

  const loadMoreHandler = () => {
    setCasesToLoad(previousCount => previousCount + casesPerPage)
  }

  const caseStudiesRemainToLoad = caseStudies.length - limitedCaseStudies.length

  return (
    <section className="CaseStudySection GlobSectPadSpacing grid grid-cols-12 gap-x-m mx-0 lg:mx-m">
      {lead && <h2 className='FontL block col-span-12 mb-mobile-s mx-mobile-xs lg:mx-0 lg:mb-s'>{lead}</h2>}
      {limitedCaseStudies &&
        limitedCaseStudies.map((caseStudy, index) => {
          return (
            <div className={sectionStyles.containers[index % sectionStyles.containers.length]} key={index}>
              <ImageWithText {...caseStudy} style={sectionStyles.images[index % sectionStyles.images.length]} category={category} grids={sectionStyles.grids[index]} />
            </div>
          )
        })
      }
      {(loadMore && !!caseStudiesRemainToLoad) &&
        <div
          className="FontS col-span-12 text-center mt-mobile-l mb-mobile-l lg:mt-xl lg:mb-xl cursor-pointer"
          onClick={loadMoreHandler}
        >{t('common:loadMoreCases')} <sup className="text-primary">{caseStudiesRemainToLoad}</sup>
        </div>
      }
      {(seeMore && !!caseStudiesRemainToLoad) &&
        <div className="FontS col-span-12 text-center mt-mobile-m mb-mobile-l lg:mt-l lg:mb-xl">
          <ArrowButton title={t('common:seeMoreCases')} url='/work' direction={ArrowDirection.RIGHT} />
        </div>
      }
    </section>
  )
}

export default CaseStudySection
