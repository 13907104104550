import { graphql, useStaticQuery } from 'gatsby'
import { ImageItem } from '@kentico/gatsby-kontent-components/dist/image-element'
import { filterElementDataByLanguage } from '../../common/serviceHelpers'
import { DynamicSitesPath } from '../../common/typings/enums'
import _get from 'lodash/get'

/* Kentico model: CaseStudy Section*/
export interface KenticoCaseStudySection extends KontentItem {
  caseStudies: KenticoCaseStudySectionElement[]
}

/* Kentico model: CaseStudy Section Element*/
export interface KenticoCaseStudySectionElement extends KontentItem {
  listing_image_or_video: KontentAssetElement,
  listing_parallax: KontentAssetElement[],
  title: KontentTextElement,
  lead: KontentTextElement,
  slugs: KontentTextElement
}

export class CaseStudySectionElement {
  image: ImageItem
  videoUrl: string
  parallax: ImageItem[]
  title: string
  description: string
  target: string

  constructor(data: KenticoCaseStudySectionElement) {
    const listingAssetType = _get(data, 'listing_image_or_video.value[0].type', '')
    this.image = listingAssetType.includes('image') ? _get(data, 'listing_image_or_video.value[0]') : ''
    this.videoUrl = listingAssetType.includes('video') ? _get(data, 'listing_image_or_video.value[0].url') : ''
    this.parallax = _get(data, 'listing_parallax.value', [])
    this.title = _get(data, 'title.value', '')
    this.description = _get(data, 'subtitle.value', '')
    this.target = _get(data, 'slugs.value', '')
  }
}

export class CaseStudySection {
  caseStudies: CaseStudySectionElement[]

  constructor(data: KenticoCaseStudySectionElement[]) {
    this.caseStudies = data
      .filter(Boolean)
      .map((element: KenticoCaseStudySectionElement) => {
        return new CaseStudySectionElement(element)
      })
  }
}

export const caseStudySectionFragment = graphql`
  query CaseStudySectionQuery {
    allKontentItemCaseStudy(sort: {order: [DESC, DESC], fields: [elements___display_order___value, system___last_modified]}) {
      ...kontent_item_case_studyConnectionFragment
    }
  }
`

export const caseStudySectionNodesFragment = graphql`
  fragment kontent_item_case_studyConnectionFragment on kontent_item_case_studyConnection {
    nodes {
      id
      preferred_language
      elements {
        title {
          value
        }
        slugs {
          value
        }
        subtitle {
          value
        }
        listing_image_or_video {
          value {
            url
            description
            height
            name
            size
            type
            width
          }
        }
        listing_parallax {
          value {
            url
            description
            height
            name
            size
            type
            width
          }
        }
      }
    }
  }
`

export const useCaseStudySectionData = (language: string) => {
  const data = useStaticQuery(caseStudySectionFragment)
  const caseStudySectionData: KenticoCaseStudySectionElement[] =
    filterElementDataByLanguage(data, language, DynamicSitesPath.WORK).map((caseStudy: any) => caseStudy.elements)
  return new CaseStudySection(caseStudySectionData)
}

// section styles for Case Study Section
export const sectionStyles = {
  containers: [
    'col-start-1 col-end-13 mt-mobile-l lg:col-start-1 lg:col-end-13 lg:mt-l',    //row 1
    'col-start-1 col-end-13 mt-mobile-l lg:col-start-1 lg:col-end-9 lg:mt-l',     //row 2
    'col-start-1 col-end-13 mt-mobile-l lg:col-start-9 lg:col-end-13 lg:mt-l',    //row 2
    'col-start-1 col-end-13 mt-mobile-l lg:col-start-1 lg:col-end-5 lg:mt-l',     //row 3
    'col-start-1 col-end-13 mt-mobile-l lg:col-start-5 lg:col-end-9 lg:mt-l',     //row 3
    'col-start-1 col-end-13 mt-mobile-l lg:col-start-9 lg:col-end-13 lg:mt-l',    //row 3
    'col-start-1 col-end-13 mt-mobile-l lg:col-start-1 lg:col-end-5 lg:mt-l',     //row 4
    'col-start-1 col-end-13 mt-mobile-l lg:col-start-5 lg:col-end-13 lg:mt-l'     //row 4
  ],
  images: [
    'h-mobile-xxxl lg:h-xxxxl',    //row 1
    'h-mobile-xxxl lg:h-xxxxl',    //row 2
    'h-mobile-xxxl lg:h-xxxl',     //row 2
    'h-mobile-xxxl lg:h-xxxl',     //row 3
    'h-mobile-xxxl lg:h-xxxxl',    //row 3
    'h-mobile-xxxl lg:h-xxxl',     //row 3
    'h-mobile-xxxl lg:h-xxxl',     //row 4
    'h-mobile-xxxl lg:h-xxxxl'     //row 4
  ],
  grids: [
    'lg:grid lg:grid-cols-12 lg:gap-x-m',     //row 1
    'lg:grid lg:grid-cols-8 lg:gap-x-m',      //row 2
    'lg:grid lg:grid-cols-6 lg:gap-x-m',      //row 2
    'lg:grid lg:grid-cols-6 lg:gap-x-m',      //row 3
    'lg:grid lg:grid-cols-6 lg:gap-x-m',      //row 3
    'lg:grid lg:grid-cols-6 lg:gap-x-m',      //row 3
    'lg:grid lg:grid-cols-6 lg:gap-x-m',      //row 4
    'lg:grid lg:grid-cols-8 lg:gap-x-m'       //row 4
  ]
}