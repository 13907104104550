import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

//components
import Layout from '../components/Layout'
import InNumbers from '../components/InNumbersSection'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import LinksSection from '../components/LinksSection'
import HeroSection from '../components/HeroSection'
import FloatingButton from '../components/FloatingButton'
import CaseStudySection from '../components/CaseStudySection'
import ClientsSection from '../components/ClientsSection'
import TestimonialsSection from '../components/TestimonialsSection'
import AccordionSection from '../components/AccordionSection'
import ImageVideoSection from '../components/ImageVideoSection'
import LastArticlesSection from '../components/LastArticlesSection'

//models, hooks
import { useSeoData } from '../components/SeoComponent/model'
import { IsMobileContext, LanguageContext } from '../common/Context'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useHeroSectionData, HeroSection as HeroSectionClass } from '../components/HeroSection/model'
import { useInNumbersSectionData, InNumbersSection } from '../components/InNumbersSection/model'
import { useClientsSectionData, ClientsSection as ClientsSectionClass } from '../components/ClientsSection/model'
import { useTestimonialsSectionsData, TestimonialsSection as TestimonialsSectionClass } from '../components/TestimonialsSection/model'
import { useLastArticlesSectionData, LastArticlesSection as LastArticlesSectionClass } from '../components/LastArticlesSection/model'
import { useLinksGridData, LinksGrid } from '../components/LinksGrid/model'
import { useNavbarData, Navbar as NavbarClass } from '../components/Navbar/model'
import { useCaseStudySectionData, CaseStudySection as CaseStudySectionClass } from '../components/CaseStudySection/model'
import { useImageVideoSectionData, ImageVideoSection as ImageVideoSectionClass} from '../components/ImageVideoSection/model'
import { useAccordionSectionData, AccordionSection as AccordionSectionClass } from '../components/AccordionSection/model'
import { useNavbarDarkMode } from '../hooks/useNavbarInDarkMode'

//other
import { ArrowDirection } from '../common/typings/enums'
import withSSR from '../hoc/withSSR'
import './index.scss'

const IndexPage: React.FC<PageProps> = ({ location: { pathname } }) => {
  const { language: initialLanguage } = React.useContext(LanguageContext)
  const [language, setLanguage] = React.useState(initialLanguage)

  /*  For data collecting hooks below (e.g. useLinksGridData), 
  You need to know page codename from kentico and optionally section codename.
  Data service is searching query for this elements and collects the right data to components  */
  const pageCodename = 'home_page'
  const seoData = useSeoData(language, pageCodename, pathname)
  const pageData = {
    imageVideoSectionData1: useImageVideoSectionData(language, pageCodename, 'image_video_section'),
    imageVideoSectionData2: useImageVideoSectionData(language, pageCodename, 'image_video_section_2'),
    heroSectionData: useHeroSectionData(language, pageCodename),
    inNumbersData: useInNumbersSectionData(language, pageCodename),
    clientsSectionData: useClientsSectionData(language, pageCodename),
    testimonialsSectionData: useTestimonialsSectionsData(language, pageCodename),
    caseStudySectionData: useCaseStudySectionData(language),
    linksGridData: useLinksGridData(language, pageCodename),
    navbarData: useNavbarData(language, pageCodename, pathname),
    accordionSectionData: useAccordionSectionData(language, pageCodename),
    lastArticlesSectionData: useLastArticlesSectionData(language),
  }

  return (
    <Layout seoData={seoData} language={language} setLanguage={setLanguage}>
      <IndexPageTemplateWithSSR pageData={pageData} />
    </Layout>
  )
}

interface IndexPageProps {
  isMobile: boolean
  pageData: {
    imageVideoSectionData1: ImageVideoSectionClass
    imageVideoSectionData2: ImageVideoSectionClass
    heroSectionData: HeroSectionClass
    inNumbersData: InNumbersSection
    clientsSectionData: ClientsSectionClass
    testimonialsSectionData: TestimonialsSectionClass
    caseStudySectionData: CaseStudySectionClass
    linksGridData: LinksGrid
    navbarData: NavbarClass
    accordionSectionData: AccordionSectionClass | null
    lastArticlesSectionData: LastArticlesSectionClass
  }
}

const IndexPageTemplate: React.FC<IndexPageProps> = ({
  isMobile,
  pageData: {
    imageVideoSectionData1,
    imageVideoSectionData2,
    heroSectionData,
    inNumbersData,
    clientsSectionData,
    testimonialsSectionData,
    caseStudySectionData,
    linksGridData,
    navbarData,
    accordionSectionData,
    lastArticlesSectionData,
  }
}) => {
  const { t } = useTranslation()
  const navbarRef = React.useRef<React.ElementRef<typeof Navbar>>(null)
  const { navbarInDarkMode } = useNavbarDarkMode(isMobile, navbarRef)

  return (
    <IsMobileContext.Provider value={{ isMobile }}>
      <header>
        <Navbar {...linksGridData} {...navbarData} darkMode={navbarInDarkMode} ref={navbarRef} />
      </header>
      <main className="HomePage">
        <HeroSection
          {...heroSectionData}
          headingClass="FontXXL"
          headingColumns={{desktop: 9, mobile: 12}} descriptionColumns={{desktop: 6, mobile: 12}}
        >
          <LinksSection {...linksGridData} />
        </HeroSection>
        <CaseStudySection {...caseStudySectionData} lead={t('common:whatWeDid')} seeMore />
        <ImageVideoSection {...imageVideoSectionData1} arrowDirection={ArrowDirection.OBLIQUE} />
        {accordionSectionData && <AccordionSection {...accordionSectionData} />}
        <InNumbers {...inNumbersData} />
        <LastArticlesSection {...lastArticlesSectionData} lead={t('common:sharingIsCaring')} />
        <ClientsSection {...clientsSectionData} />
        <TestimonialsSection {...testimonialsSectionData} />
        <ImageVideoSection {...imageVideoSectionData2} />
      </main>
      <footer>
        <Footer {...linksGridData} />
      </footer>
      <FloatingButton />
    </IsMobileContext.Provider>
  )
}
const IndexPageTemplateWithSSR = withSSR<IndexPageProps>(IndexPageTemplate)

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common","index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
